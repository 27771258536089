import React from 'react'
import { Link } from 'gatsby'

import Logo from '../../../static/svg/logo-raj-input.svg'

// import logo from '../../../static/img/logo-yellowbg-white.png'

interface Props {
  title: string
  location: Location
}

const Navibar: React.FC<Props> = ({ location, title }: Props) => {
  return (
    <nav className="navbar navbar-expand navbar-dark flex-column flex-md-row bg-primary px-3 px-lg-5">
      {/* <div className="container-fluid "> */}
      <div className="px-2 mr-lg-auto bd-highlight">
        <Link className="text-center" to="/">
          {/* <h1 className="text-secondary mb-0 navbar-brand">{title}</h1> */}
          <h1 className="text-secondary mb-0 navbar-brand">
            <Logo />
          </h1>
        </Link>
      </div>
      {/* <div className="p-2 bd-highlight">Flex item</div> */}
      <div className="ml-auto p-2 bd-highlight">
        <p className="col-12 d-none text-secondary d-lg-block mb-0">
          <a
            className="link "
            href="https://www.youtube.com/channel/UCxwG9Jwuidhhm1DRLC9Vwjw"
            target="_blank"
          >
            YOUTUBE{' '}
          </a>{' '}
          <span className="text-accent">-</span>
          <a
            className="link "
            href="https://www.instagram.com/robinandlenu/"
            target="_blank"
          >
            {' '}
            INSTAGRAM{' '}
          </a>{' '}
          <span className="text-accent">-</span>
          <a
            className="link "
            href="https://www.facebook.com/robinandlenu/"
            target="_blank"
          >
            {' '}
            FACEBOOK{' '}
          </a>
        </p>
      </div>

      {/* <div className="navbar-nav-scroll">
          <ul className="navbar-nav bd-navbar-nav flex-row">
            <li
              className={
                location.pathname === '/' ? 'nav-item active' : 'nav-item'
              }
            >
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li
              className={
                location.pathname === '/profile/'
                  ? 'nav-item active'
                  : 'nav-item'
              }
            >
              <Link to="/profile/" className="nav-link">
                Profile
              </Link>
            </li>
          </ul>
        </div> */}
      {/* <p className="col-12 d-none d-lg-block mb-0">
          YOUTUBE - INSTAGRAM - FACEBOOK
        </p> */}
      {/* <ul className="nav justify-content-end">
          <li className="nav-item">
            <a className="nav-link active" href="#">Active</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Link</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Link</a>
          </li>
          <li className="nav-item">
            <a className="nav-link disabled" href="#">Disabled</a>
          </li>
        </ul> */}
      {/* <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" /> */}
      {/* </div> */}
    </nav>
  )
}

export default Navibar
